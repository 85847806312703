import { render, staticRenderFns } from "./body-nutrition.vue?vue&type=template&id=0ba4808a"
import script from "./body-nutrition.vue?vue&type=script&lang=js"
export * from "./body-nutrition.vue?vue&type=script&lang=js"
import style0 from "./_body-nutrition.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports