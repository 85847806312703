
import { mapActions } from 'vuex';

import ImgLoader from '@/components/core/img-loader/img-loader.vue';
import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';

export default {
  components: {
    ImgLoader,
  },
  props: {
    titleHero: {
      type: String,
      default: null,
    },
    landingPage: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getLanguageTags() {
      return this.landingPage ? this.landingPage : this.$t(`landingPage`);
    },
    getTitle() {
      const variantMaps = {
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY]: this.titleHero || this.$t(`landingPage.title`),
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_CORTISOL_CLEANSE]: this.$t(`landingPage.nutritionMonthlyCortisolCleans.title`),
        [QUIZ_VARIANTS.NUTRITION_WEEKLY_CORTISOL_CLEANSE]: this.$t(`landingPage.nutritionWeeklyCortisolCleans.title`),
      };
      return variantMaps[this.getQuizVariant] || variantMaps[QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY];
    },
    isOtherButtonAvailable() {
      return this.getQuizVariant === 'symptoms';
    },
  },
  methods: {
    ...mapActions('quiz', ['pushQuizAnswerToTheStoreAction', 'setDefaultStateValue']),
    handleDefaultState(value) {
      this.pushQuizAnswerToTheStoreAction({ id: 'weight', answer: value });
      this.setDefaultStateValue(value);
      this.$router.push('/quiz/start');
    },
  },
};
